<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                BUSINESS PROFILE
              </h2>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    COMPREHENSIVE ADVISORY SERVICES ON THE ENERGY SECTOR AND REAL ESTATE MARKET
                  </v-card-title>
                  <v-card-text>
                    Our consulting firm specializes in the energy, real estate, and finance sectors, offering a comprehensive suite of services and solutions. We excel in providing strategic oversight and operational expertise to drive business transformation and manage transitions effectively. Our interim management services are designed to deliver immediate results, addressing critical challenges and leveraging opportunities within these dynamic industries. By integrating cutting-edge market insights and financial acumen, we ensure our clients achieve sustainable growth, operational efficiency, and enhanced profitability. Our approach is tailored to meet the unique needs of each sector, from optimizing energy projects and sustainable real estate development to navigating complex financial landscapes.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                MISSION AND VISION
              </h2>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    Implementation of sustainable energy solutions that not only meet the current needs of clients but also safeguard the future of our planet. We transform energy and real estate challenges into opportunities with a focus on creating value, enhancing sustainability, and fostering long-term relationships with clients.<br>
                    <p class="text-right"><i>Innovation driven consulting expertise</i></p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col
                md="5"
                offest-md="1"
                sm="12"
              >
                <v-card
                  outlined
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-img src="/static/e2.jpg" />
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                BOARD OF DIRECTORS
              </h2>
            </v-col>
            <v-container
              class="d-md-inline-flex"
              style="font-family:'Kanit'!important"
            >
              <!-- <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/baczkowskipawel.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Paweł Bączkowski
                </p>
                <p style="font-size:0.75rem">
                  PREZES ZARZĄDU
                </p>
              </v-col> -->
              <v-col
                md="6"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/sawickitomasz.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Tomasz Sawicki
                </p>
                <!-- <p style="font-size:0.75rem">
                  WICEPREZES ZARZĄDU
                </p> -->
              </v-col>
              <v-col
                md="6"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/dlugoleckimarcin.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Marcin Długołęcki
                </p>
                <!-- <p style="font-size:0.75rem">
                  DORADCA ZARZĄDU DS. ROZWOJU
                </p> -->
              </v-col>
              <!-- <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/jenkinsadam.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Adam Jenkins
                </p>
                <p style="font-size:0.75rem">
                  WICEPREZES ZARZĄDU
                </p>
              </v-col> -->
            </v-container>
          </v-col>
        </v-row>
        <v-row
          class="pb-5 pt-5 background-light-grey"
        >
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                PARTNERS AND CONTRACTORS
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                WE ARE WORKING WITH THE BEST IN THE INDUSTRY
              </p>
            </v-col>
            <v-col cols="12">
              <!-- <div class="mb-2">
                <p class="mb-1">
                  Prezentacja struktury spółki Polgrid SA i projektów w przygotowaniu
                  <a
                    href="/static/PolgridSA.pdf"
                    download
                  >[pobierz]
                  </a>
                  <a
                    href="/static/PolgridSA.pdf"
                    target="_blank"
                  >[zobacz]
                  </a>
                </p>
              </div> -->
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="3"
                sm="12"
                class="text-center"
              >
                <v-img src="/static/logo-serwis-team.png" />
              </v-col>
              <v-col
                md="3"
                sm="12"
              >
                <v-img src="/static/logo-zeneris.png" />
              </v-col>
              <v-col
                md="3"
                sm="12"
              >
                <v-img src="/static/logo-arsago.png" />
              </v-col>
              <v-col
                md="3"
                sm="12"
                class="text-center"
              >
                <v-img src="/static/logo-cobra.png" />
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
